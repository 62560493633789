@tailwind components;
@tailwind utilities;

/* Load reset styles */
@import 'assets/styles/normalize.css';
/* Load global styles */
@import 'assets/styles/global.css';
/* Load animations */
@import 'assets/styles/animations.css';

@layer components {
    .outlet {
        min-height: calc(100% - 70px);
        overflow-y: auto;
    }
}

*::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 10px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
