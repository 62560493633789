html {
    font-size: 100%;
}

html,
body,
#root {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.toast > div[role] {
    margin: 0 0 0 8px;
}

.active-link {
    background-color: #f4f4f4 !important;
    color: theme('colors.heading') !important;
}

.active-link span {
    left: 0;
}

.active-link .tick {
    background-color: theme('backgroundColor.secondary.600') !important;
}

.active-link i {
    background-color: theme('backgroundColor.secondary.600');
    left: 0;
}

.active-group {
    background-color: theme('backgroundColor.base.200') !important;
}

.active-group .tick {
    left: 0;
}

.active-group .chevron {
    transition: all 0.2s ease;
    color: theme('backgroundColor.heading');
}

.active-group svg.chevron.expanded-menu {
    transform: rotate(90deg);
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow {
    border-color: theme('borderColor.base.300') !important;
}

.ql-toolbar.ql-snow {
    border-radius: 14px 14px 0 0;
}

.ql-container.ql-snow {
    border-radius: 0 0 14px 14px;
}

.ql-editor p {
    margin-bottom: theme('margin[3.5]') !important;
}

.rich-content ul,
.rich-content ol,
.rich-content li {
    list-style: unset;
    margin: unset;
    padding: unset;
}

.rich-content h1,
.rich-content h2,
.rich-content h3,
.rich-content h4,
.rich-content h5,
.rich-content h6,
.rich-content h7,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6,
.ql-editor h7 {
    margin-bottom: theme('margin[2]') !important;
}

.rich-content {
    color: theme('colors.base.500');
    font-size: theme('fontSize.sm');
    white-space: break-spaces;
}

.rich-content li {
    list-style-position: inside;
}

.rich-content * {
    color: theme('colors.base.500');
}

.rich-content li {
    font-size: theme('fontSize.sm');
}

.rich-content p {
    font-size: theme('fontSize.sm');
}

.rich-content p:not(:last-child) {
    margin-bottom: theme('margin[3.5]');
}

.rich-content li:not(:last-child) {
    margin-bottom: theme('margin[2.5]');
}

.rich-content p[data-f-id] {
    display: none !important;
}

.react-datepicker {
    border: unset;
    border-radius: 14px;
    box-shadow: 0px 20px 50px rgb(44 63 88 / 15%);
    display: flex;
}

.react-datepicker-time__header {
    font-size: 16px !important;
    padding: 16px;
    border-bottom: 1px solid #e8e9eb;
    color: #123f73;
}

.react-datepicker__time-container {
    border-left: 1px solid #e8e9eb;
}

.react-datepicker__close-icon {
    height: unset;
    right: 11px;
    top: 42px;
}

.react-datepicker,
.react-datepicker-popper,
.react-datepicker__tab-loop,
.react-datepicker__month-container {
    width: 100%;
}

.react-datepicker__month-container {
    padding: theme('padding.4');
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: unset !important;
    min-width: 40px;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__header {
    background: theme('backgroundColor.base.100');
    border: unset;
    padding: 0;
}

.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
    flex-direction: row;
}

.react-datepicker__day-names,
.react-datepicker__month {
    padding-top: theme('padding.4');
}

.react-datepicker__day-name,
.react-datepicker__day {
    color: theme('textColor.base.500');
    font-family: theme('fontFamily.sans');
    flex: 1;
    margin: 0;
    text-transform: uppercase;
}

.react-datepicker__month {
    margin: 0;
}

.react-datepicker__day {
    border-radius: 14px;
    color: theme('colors.heading');
    font-weight: 500;
    padding: 6px 3px;
    transition: all 0.2s ease;
}

.react-datepicker__day:hover {
    border-radius: 14px;
}

.react-datepicker-popper {
    z-index: theme('zIndex.50');
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background: theme('backgroundColor.secondary.500');
    color: theme('colors.white');
}

.react-datepicker__day--in-range {
    /* prettier-ignore */
    background: theme('backgroundColor.secondary.100')33;
    color: theme('backgroundColor.secondary.500');
    border-radius: 0px;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
    /* prettier-ignore */
    background: theme('backgroundColor.secondary.100')66 !important;
    color: theme('backgroundColor.secondary.600') !important;
}

.react-datepicker__day--range-start {
    border-radius: 14px 0 0;
}

.react-datepicker__day--range-end {
    border-radius: 0 0 14px 0;
}

.react-datepicker__day--today {
    /* prettier-ignore */
    background: theme('backgroundColor.secondary.100')55;
    color: theme('backgroundColor.secondary.500');
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
    background: theme('backgroundColor.secondary.600');
    color: theme('backgroundColor.base.100');
}

.react-datepicker__day--today:hover {
    /* prettier-ignore */
    background: theme('backgroundColor.secondary.100')AA;
    color: theme('backgroundColor.secondary.600');
}

.react-datepicker__day.react-datepicker__day--disabled {
    background: unset;
    color: theme('colors.base.400');
}

.react-datepicker__portal {
    background: rgb(89 95 105 / 0.3);
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    line-height: 2.5;
}

.checked:after {
    content: '';
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 26 26"><path d="M.3 14c-.2-.2-.3-.5-.3-.7s.1-.5.3-.7l1.4-1.4c.4-.4 1-.4 1.4 0l.1.1 5.5 5.9c.2.2.5.2.7 0L22.8 3.3h.1c.4-.4 1-.4 1.4 0l1.4 1.4c.4.4.4 1 0 1.4l-16 16.6c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3L.5 14.3.3 14z" fill="white" /></svg>');
    display: block;
    height: 12px;
    width: 12px;
}

.radio:after {
    background: theme('backgroundColor.secondary.500');
    border-radius: 50%;
    content: '';
    height: 8px;
    transform: scale(0);
    transform-origin: center;
    transition: all 0.2s ease;
    width: 8px;
}

.radio-selected:after {
    transform: scale(1) !important;
}

.custom-shadow {
    filter: drop-shadow(0px 20px 50px rgba(44, 63, 88, 0.15));
}

.notifications button:not(:last-child) {
    margin-bottom: 4px;
}

.error {
    background: #fafdfd;
}

.error > a {
    text-decoration: underline;
    color: theme('backgroundColor.primary.700');
}

.proposals > .MuiToolbar-root {
    padding: 1rem 1.5rem !important;
}

.proposals .MuiTableCell-paddingCheckbox > div button:disabled {
    display: none !important;
}

.meeting-records .MuiTypography-h6 {
    color: theme('colors.heading');
    line-height: 28px;
}

.dropzone-scale {
    transform: scale(1.05);
    transition: transform 0.2s ease;
}

.editor-error .ql-toolbar,
.editor-error .ql-container {
    border-color: theme('backgroundColor.system.danger.500') !important;
}

.management .MuiTabs-indicator {
    display: none;
}

.ReactModal__Overlay--after-open {
    z-index: 9999 !important;
}

.ril__outer,
.ril__toolbarRightSide {
    background: rgb(89 95 105 / 0.3) !important;
}

.ril__toolbarRightSide {
    padding-right: 0;
}

.ril__toolbar {
    background: transparent !important;
}

@keyframes slideRight {
    from {
        transform: translateX(-150%);
    }
    to {
        transform: translateX(0%);
    }
}

.mobile-sidebar {
    animation: slideRight ease 0.2s;
}

@media only screen and (max-width: 519px) {
    .MuiPaginationItem-icon,
    .MuiPaginationItem-root {
        font-size: 15px !important;
    }
    .MuiPaginationItem-root {
        min-width: 26px;
        height: 26px;
    }

    .MuiPagination-ul li:not(:last-child) {
        margin-right: 3px;
    }
}
