@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes newPulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.animate__pulse {
    -webkit-animation-name: newPulse;
    animation-name: newPulse;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
}

.ripple {
    transform: scale(0);
    animation: ripple 600ms linear;
}

.fade-in {
    animation: fadeIn 0.2s linear;
}
